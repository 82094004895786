<template>
  <div class="check-google">
    <h2>Any PDF file</h2>

    <div
      id="canvas"
      style="display:none"
    ></div>
    <dragn-drop @file-change="handleFile"></dragn-drop>

    <div class="card mt-2 ">
      <div class="card-body">
        <h3>
          {{ textToShow }}
        </h3>
      </div>
    </div>

    <div
      class="card mt-2 "
      v-for="(page,index) in results"
      :key="page.pageNumber"
    >
      <div class="card-title">{{ page.pageNumber }}</div>
      <div class="card-body">
        {{ page.responses[0].textAnnotations[0].description }}
      </div>
    </div>
  </div>
</template>

<style>
tr.bg-gray {
  background: rgba(0, 0, 0, 0.05);
}
</style>

<script>
import _ from "lodash";

import BaseConvert from "./convert-pdf-to-image.vue";
import DragnDrop from "./dragndrop.vue";

export default {
  extends: BaseConvert,
  components: {
    DragnDrop,
  },

  data() {
    return {
      textToShow: "",
      results: [],
    };
  },
  methods: {
    clearInputFile() {
      document.getElementById("file").value = null;
    },
    async handleFile(file) {
      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);

      // let url = "https://legal-ocr-function.azurewebsites.net/api/testgoogle";
      // let disabledBaseUrl = true;

      let url = "/testgoogle";
      let disabledBaseUrl = false;

      this.textToShow = "Loading....";

      return this.$http
        .uploadFile(url, formData, {
          disabledBaseUrl,
        })
        .then((res) => {
          this.results = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.textToShow = "";
        });
    },
  },
};
</script>
